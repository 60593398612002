import {Dialog, DialogBody} from '@/Components/Catalyst/dialog'
import React, {PropsWithChildren, useState} from 'react'
import VideoEmbed from "@/Pages/Recipe/partials/VideoEmbed";
import {FaInstagram, FaTiktok, FaYoutube} from "react-icons/fa";
import {VideoCameraIcon} from "@heroicons/react/20/solid";
import RecipeLinkData = App.Data.Models.RecipeLinkData;


function SocialIcon({host}: { host: string }) {
  if (host.includes('youtube')) {
    return <FaYoutube className='size-10 h-auto size-10 cursor-pointer pr-1 text-black text-red-700'/>
  }

  if (host.includes('insta')) {
    return <FaInstagram className='size-10 h-auto size-10 cursor-pointer pr-1 text-black text-black'/>
  }

  if (host.includes('tiktok')) {
    return <FaTiktok className='size-10 h-auto size-10 cursor-pointer pr-1 text-black text-black'/>
  }

  return <VideoCameraIcon className='size-10 h-auto size-10 cursor-pointer pr-1 text-black text-black'/>
}

interface Props extends PropsWithChildren {
  recipeVideo: RecipeLinkData
}

export default function RecipeVideoDialog({recipeVideo, children}: Props) {
  let [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        className='flex items-center justify-center cursor-pointer'
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {!children && <SocialIcon host={new URL(recipeVideo.url ?? '').hostname}/>}
        <p
          className='text-lg antialiased transition-all'
        >
          {
            children
              ? children
              : `${recipeVideo.label} on ${
                new URL(recipeVideo.url ?? '')
                  .hostname
                  .replace('www.', '')
              }`
          }
        </p>
      </div>


      <Dialog
        open={isOpen}
        onClose={setIsOpen}
        size='5xl'
        className='h-full w-full'
      >
        <DialogBody>
          <VideoEmbed recipeLink={recipeVideo}/>
        </DialogBody>
      </Dialog>
    </>
  )

}
